<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Volunteering" subtitle="Help make the convention a success by volunteering for available roles and slots." :tabs="tabs" >

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="No roles found." :is-grid="true">

		<app-card :to="{ name: 'Convention.Volunteers.Role', params: { 'id': item.id }, query: { redirect: 'roles' } }" v-for="item in collection" :key="item.id" :title="item.name" :metrics="metrics(item)" :image="item.image" />

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			pagination: false,
			endpoint: 'convention/volunteers/roles'
		}

	},

	created: function() {

		if (!this.$canVolunteer) this.$router.push({
			name: 'Convention.Volunteers.Register'
		})

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Volunteers.Roles', text: 'Roles' },
				{ name: 'Convention.Volunteers.Slots', text: 'Slots' },
				{ name: 'Convention.Volunteers.Assignments', text: 'My Assignments', count: this.$store.getters['session/count'].volunteers.total }
			]

		}

	},

	methods: {

		metrics: function(item) {

			var metrics = []

			metrics.push({
				theme: (item.count.capacity - item.count.filled) ? 'red' : 'green',
				label: 'more volunteer' + ((item.count.capacity - item.count.filled === 1) ? '' : 's' ) + ' needed',
				singular: true,
				value: item.count.capacity - item.count.filled
			})

			return metrics

		}

	}

}

</script>

<style scoped>

</style>